.qr-card {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 315px;
    width: 300px;
    align-items: center;
    border-radius: 10px !important;
}

.container-btn-qr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.container-save {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.download-btn-qr {
    color: blue !important;
}

.download-btn-qr:hover {
    color: rgb(228, 12, 12) !important;
    background-color: transparent !important;
}

.file-type-download {
    font-size: 2rem;
}

@media only screen and (max-width: 999px) {
    .qr-card {
        width: 100%;
    }
}