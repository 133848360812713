.body {
    background-color: #f1f5ff
}


.qrinfo-body-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    height: 100vh;
}



.card-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 35px;
    align-items: center;
    height: fit-content;
}

.qr-details-content {
    margin-top: 2rem;
}

.box-qr-img {
    display: flex;
    flex-wrap: wrap;

}

.box-pin-id-type {
    display: flex;
    flex-direction: column;
    margin-right: 45px;
}

.card-input .MuiTextField-root {
    margin: 10px;
}

.card-info {
    padding: 15px;
}

.btn-checkqr {
    background-color: rgb(91, 192, 222) !important;
    color: #fff !important;
    margin-top: 18px !important;
    width: 92%;
}

.qr-box-details {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    align-content: center;
}

.text-details {
    max-width: 800px;


}

.qr-details-card {
    margin-top: 4rem;
    min-width: 25rem;
    border-radius: 10px !important;
}

/* header */

.profile-heading-info {
    border-radius: 8px;
    width: 100%;
    display: flex;
    padding: 1.5rem;
    align-items: center;
    background-color: #02c1ec;
    gap: 2rem;
    overflow: hidden;
    flex-wrap: wrap;
}

.floating-heading-info {
    margin: 10px;
    transform: translateY(0);
    animation: fl1 3s infinite linear;
}

.floating-heading-info::before {
    content: attr(data-text);
    position: absolute;
    clip-path: polygon(0, 0, 100%, 0, 100%, 50%, 0, 50%);
    animation: fl1 3s infinite linear;
}

@keyframes fl1 {
    30% {
        transform: translateY(10%);
    }
}

.box-info-search-info {
    border-radius: 8px;
    height: 120px;
    width: 120px;
    padding: 5px;
    background-color: #fff;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ButtonLink.css */
.circle-button-link {
    padding: 10px;
    color: rgb(91, 192, 222);
}

.circle-button-link:hover {
    color: #34db9b;

}

.type-tag {
    background-color: #a9a9c1;
    padding-left: 5px;
    padding-right: 5px;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    margin: 0.1rem 0 1.5rem 0;
}

.type-tag-letters {
    color: white;

}