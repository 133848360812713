/* Card container */
.card-container {
    position: relative;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    margin: 1rem;
    padding-top: 1rem;
    padding-left: 2rem;
    border-radius: 10px !important;
    transition: all 0.5s ease-in-out !important;
}

.dynamic:hover {
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.25), 0 10px 10px #02c1ec41;
    cursor: pointer;

}

.box-info {
    display: flex;
    flex-direction: column;
    margin-left: inherit;
}

.title-view-card {
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}

.card-content-info {
    flex: 1 0 auto;

}

/* Dynamic label */
.card-content-info .dynamic-label {
    background-color: rgb(211 229 170);
    color: white;
    position: absolute;
    font-size: 0.8rem;
    top: 0;
    left: 0;
    padding-left: 1.5rem;
    padding-right: 1.75rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    transform: translate(-25px, 16px) rotate(-45deg);
}

/* Dynamic label */
.card-content-info .custom-label {
    background-color: rgb(216 180 224);
    color: white;
    position: absolute;
    font-size: 0.8rem;
    top: 0;
    left: 0;
    padding-left: 1.5rem;
    padding-right: 1.75rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    transform: translate(-25px, 16px) rotate(-45deg);
}

.createon-box {
    display: flex;
    flex-direction: row;
    padding-top: 1;
}

.box-qr-btn {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: inherit;
}


.access-qr-btn {
    background-color: rgb(91, 192, 222) !important;
    color: #fff !important;
    margin-top: 5px !important;
    width: 150px;
    height: 23px;
}

.access-qr-btn:hover {
    background-color: rgb(81, 81, 241) !important;
}

@media only screen and (max-width: 700px) {
    .card-container {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        align-content: center;
        align-items: center;
    }
}