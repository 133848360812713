.profile-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 1rem 2rem;
}

.profile-container1 {
  display: flex;
  justify-content: center;

}

.profile-card {
  width: fit-content;
  border-radius: 10px !important;
  margin: 3rem
}

.profile-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.editBtn.profile {
  height: fit-content;
  padding: 4px;
  margin: 2rem;
  background-color: rgb(91, 192, 222);
  width: 9rem;

}

@media (max-width: 606px) {
  .editBtn.profile {
    margin-top: 0;
    width: 14rem;
  }
}