.container-dynamic {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 100%;
    background: #191a1a;
    text-align: center;
}

.container-dynamic h1 {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-weight: 100;
    font-size: 3.1em;
    color: rgb(91, 192, 222);
}

.container-dynamic span {
    position: relative;
    top: 0.63em;
    display: inline-block;
    text-transform: uppercase;
    opacity: 0;
    transform: rotateX(-90deg);
}

.let1 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.2s;
}

.let2 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.3s;
}

.let3 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.4s;
}

.let4 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.5s;

}

.let5 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.6s;
}

.let6 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.7s;
}

.let7 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.8s;
}

.let8 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 1.9s;
}

.let9 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 2s;
}

.let10 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 2.1s;
}

.let11 {
    animation: drop 1.2s ease-in-out infinite;
    animation-delay: 2.2s;
}

@keyframes drop {
    10% {
        opacity: 0.5;
    }

    20% {
        opacity: 1;
        top: 3.78em;
        transform: rotateX(-360deg);
    }

    80% {
        opacity: 1;
        top: 3.78em;
        transform: rotateX(-360deg);
    }

    90% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        top: 6.94em
    }
}