.container-grcode {
    max-width: 100rem;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.header-qr {
    height: 20%;
}

.box-container {

    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}



@media only screen and (max-width: 999px) {

    .container-grcode {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .qr-code {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
    }

}