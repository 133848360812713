.container-location {
    display: flex;
    flex-wrap: wrap;
}

.text-field-map {
    width: 14rem;

}

.iframe_ {

    display: block;
    width: 90%;
    max-width: 60rem;
    height: 25em;
    margin-top: 1rem auto;
    border: 1px solid rgb(116, 114, 114);
}

.btn-map {
    height: 3.4rem;
}