.btn-nav.customBtn.active {
    color: rgb(245, 248, 250);
    background-color: rgb(91, 192, 222);
    /* Active button color */
}

.white-background {
    background-color: white;
}

.btn-nav.customBtn {
    color: rgb(119, 119, 119);
    margin: 15px;
    padding: 5px;

}

.container-generate-links {
    width: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.a-btn {
    font-size: small;
    margin: 4px;
}

.nav-container {

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}


.div-inputs {
    display: flex;
    flex-direction: column;
    width: 560px;
}

.div-email {
    display: flex;
    flex-direction: column;
}

.div-sms {
    display: flex;
    flex-direction: column;
}

.dic-imput-skype {
    display: flex;
    flex-direction: column;
}

.div-inputs-event {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.div-inputs-texfile {
    display: flex;
    flex-direction: row;
}

.div-inputs-texfile-event {
    display: flex;
    flex-direction: row;
}

.container-fields {
    display: flex;
    flex-direction: row;

}

.text-fields {
    margin: 0 10px 0 0;
    width: 300px;
}

.container-fields-wifi {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.wifi-div-textFields {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

@media only screen and (max-width: 850px) {

    .div-inputs-texfile-event {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .div-inputs {
        width: auto;
    }

    .div-inputs {
        display: flex;
        flex-direction: column;
        margin: 0;
        flex-wrap: wrap;
    }

    .container-generate-links {
        width: 100%;

    }



}