.formBuilderOuter {
  padding: 15px 0px;
  height: 100%;

  margin: 0 auto;
}

.leftDrawer {
  position: absolute;
  top: 100px;
}

.leftDrawerWrapper {
  background: #3e4652;
  padding: 10px;
  color: white;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.leftDrawerTitle {
  line-height: 16px;
  text-align: center;
  width: 74px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 6px;
}

.rightDrawer {
  position: absolute;
  top: 150px;
  right: 30px;
  transform: translate(-50%, -50%);
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translate(-50%, -50%) translateY(0);
  }

  50% {
    transform: translate(-50%, -50%) translateY(-10px);
  }

  100% {
    transform: translate(-50%, -50%) translateY(0);
  }
}

/* .rightDrawer {
  position: absolute;
  top: 100px;
  right: 30px;
} */

.rightDrawerWrapper {
  background: #0099ff;
  color: white;
  width: 60px;
  height: 60px;
  justify-content: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.rightDrawerTitle {
  line-height: 16px;
  text-align: center;
  width: 74px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 6px;
}

.formBuilderInner {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  height: 83vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.requiredField::after {
  content: "*";
  color: red;
}

.formBuilderInnerAfterSubmit {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clickToAdd {
  background: #f3f3fe;
  border-style: dashed;
  margin: 10vh 1vw;
  border-radius: 10px;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-color: #c8ceed;
  padding: 10vh 0;
  cursor: pointer;
}

.formBuilderTextFields {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.formComponentTitles {
  padding: 10px 8px;
}

.setting-delete-icon {
  visibility: hidden;
}

.parent:hover .setting-delete-icon {
  visibility: visible;
  margin-left: 1vw;
}

.selected2 {
  box-shadow: 0 0 0 2px #0293e2;
  border-radius: 5px;
}

.parent {
  display: flex;
  align-items: center;
}

.settingIcon {
  cursor: pointer;
  background: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 20px;
  margin-bottom: 5px;
}

.deleteIcon {
  margin-top: 5px;
  cursor: pointer;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 20px;
}

.formElementWrapper {
  margin: 8px 4px;
  width: 80%;
}

.submitButton {
  padding: 10px 0px;
  justify-content: center;
  display: flex;
}

.custom-container {
  display: flex;
  height: 100%;
  background: #FBFBFB;
  margin: -24px;
}

.elements-container {
  overflow: auto;
  height: 85vh;
  background: #3e4652;
  color: white;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1151px) {
  .formBuilderOuterContainer {
    width: 50%;
  }
}

@media (max-width: 1150px) {

  .elements-container {
    height: 26vh;
    border-radius: 8px;
  }

  .list-name {
    text-align: center;
  }

  .element1 {
    flex-direction: column;
  }

  .elements-list {
    display: flex;
  }

  .custom-container {
    display: flex;
    flex-direction: column;
    margin: -10px;
  }
}