/* PopUpModal.css */

.box-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: -webkit-fill-available;
    max-width: 30rem;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 20px;
}

.save-update-buttons {
    width: 200px;
    background-color: rgb(91, 192, 222) !important;
    color: #fff !important;
}

.save-update-buttons:hover {
    background-color: rgb(27, 151, 189) !important;

}