/* CSS for Box Component */
.box-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    height: 86vh;
}

/* CSS for Button Component */
.home-button {
    border-radius: 8px !important;
    background-color: rgb(91, 192, 222) !important;
    width: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* CSS for the container inside the Button */


/* CSS for the icon inside the Button */
.home-icon-button {
    font-size: 13vh !important;
}

/* CSS for the paragraph inside the Button */
.home-p-button {
    font-size: 1.1rem;
    margin-top: 0.2%;
}