.profile-heading {
    border-radius: 8px;
    width: 100%;
    display: flex;
    padding: 0.5rem;
    /* background-color: #111fff; */
    background-color: #02c1ec;
    gap: 2rem;
    overflow: hidden;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 7vh;
}

.floating-heading {
    transform: translateY(0);
    animation: fl1 3s infinite linear;
    margin: auto;
}

.floating-heading::before {
    content: attr(data-text);
    position: absolute;
    clip-path: polygon(0, 0, 100%, 0, 100%, 50%, 0, 50%);
    animation: fl1 3s infinite linear;
}

@keyframes fl1 {
    30% {
        transform: translateY(10%);
    }
}

.box {
    height: 70px;
    width: 70px;
    background-color: #fff;
    color: #02c1ec;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}